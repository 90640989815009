import React, { Component } from "react"

class Countdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  }

  componentDidMount() {
    var date = this.props.date.split("/")
    var time = this.props.time.split(":")
    setInterval(() => {
      let date_end = this.dateWithTimeZone(
        this.props.timezone,
        date[0],
        date[1],
        date[2],
        time[0],
        time[1],
        time[2]
      )
      let date_now = new Date()

      let seconds = Math.floor((date_end - date_now) / 1000)
      let minutes = Math.floor(seconds / 60)
      let hours = Math.floor(minutes / 60)
      let days = Math.floor(hours / 24)

      hours = hours - days * 24
      minutes = minutes - days * 24 * 60 - hours * 60
      seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60

      this.setState({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      })
    }, 1000)
  }

  dateWithTimeZone = (timeZone, year, month, day, hour, minute, second) => {
    let date = new Date(Date.UTC(year, month - 1, day, hour, minute, second))
    let utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }))
    let tzDate = new Date(date.toLocaleString("en-US", { timeZone: timeZone }))
    let offset = utcDate.getTime() - tzDate.getTime()

    date.setTime(date.getTime() + offset)

    return date
  }

  render() {
    const { days, hours, minutes, seconds } = this.state
    let color = "#87c1ff"
    if (days === 0 && hours < 6) {
      color = "#ff8781"
    }
    return (
      <div>
        <p>
          <span>
            <strong>Time remaining:</strong>
          </span>
          <br />
          <div style={{ color: color }}>
            {days > 0 ? (
              <span>
                {days} {days === 1 ? "day" : "days"},{" "}
              </span>
            ) : (
              ""
            )}
            {hours > 0 ? (
              <span>
                {hours} {hours === 1 ? "hour" : "hours"},{" "}
              </span>
            ) : (
              ""
            )}
            {minutes > 0 ? (
              <span>
                {minutes} {minutes === 1 ? "minute" : "minutes"},{" "}
              </span>
            ) : (
              ""
            )}
            <span>
              {seconds} {seconds === 1 ? "second" : "seconds"}{" "}
            </span>
          </div>
        </p>
      </div>
    )
  }
}

export default Countdown
