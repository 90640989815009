import React, {Component} from "react"
import Helmet from "react-helmet"
import {graphql} from "gatsby"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import styled from "@emotion/styled"
import {Layout, Link} from "$components"
import NextPrevious from "../components/NextPrevious"
import "../components/styles.css"
import config from "../../config"
import {Announce} from "../components/Announce"

const forcedNavOrder = config.sidebar.forcedNavOrder

const Edit = styled("div")`
  padding: 1rem 1.5rem;
  text-align: right;

  a {
    font-size: 14px;
    font-weight: 500;
    line-height: 1em;
    text-decoration: none;
    color: #555;
    border: 1px solid rgb(211, 220, 228);
    cursor: pointer;
    border-radius: 3px;
    transition: all 0.2s ease-out 0s;
    text-decoration: none;
    color: rgb(36, 42, 49);
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(116, 129, 141, 0.1) 0px 1px 1px 0px;
    height: 30px;
    padding: 5px 16px;
    &:hover {
      background-color: rgb(245, 247, 249);
    }
  }
`

export default class MDXRuntimeTest extends Component {
  render() {
    const {data} = this.props
    if (!data) {
      return null
    }
    const {
      allMdx,
      mdx,
      site: {
        siteMetadata: {docsLocation, title}
      }
    } = data
    const gitHub = require("../components/images/github.svg")
    const Facebook = require("../components/images/fb.svg")

    const navItems = allMdx.edges
      .map(({node}) => node.fields.slug)
      .filter(slug => slug !== "/")
      .sort()
      .reduce(
        (acc, cur) => {
          if (forcedNavOrder.find(url => url === cur)) {
            return {...acc, [cur]: [cur]}
          }

          const prefix = cur.split("/")[1]

          if (prefix && forcedNavOrder.find(url => url === `/${prefix}`)) {
            return {...acc, [`/${prefix}`]: [...acc[`/${prefix}`], cur]}
          } else {
            return {...acc, items: [...acc.items, cur]}
          }
        },
        {items: []}
      )

    const nav = forcedNavOrder
      .reduce((acc, cur) => {
        return acc.concat(navItems[cur])
      }, [])
      .concat(navItems.items)
      .map(slug => {
        if (slug) {
          const {node} = allMdx.edges.find(
            ({node}) => node.fields.slug === slug
          )

          return {title: node.fields.title, url: node.fields.slug}
        }
      })

    // meta tags
    const metaTitle = mdx.frontmatter.metaTitle
    const subTitle = mdx.frontmatter.subtitle
    const metaDescription = mdx.frontmatter.metaDescription
    const keywords = mdx.frontmatter.keywords
    let canonicalUrl = config.gatsby.siteUrl
    canonicalUrl =
      config.gatsby.pathPrefix !== "/"
        ? canonicalUrl + config.gatsby.pathPrefix
        : canonicalUrl
    canonicalUrl = canonicalUrl + mdx.fields.slug

    const isFront = canonicalUrl === "https://freejapaneselessons.com/"

    const hasRank = mdx.frontmatter.rank !== null

    const schemaOrg = [
      {
        "@context": `https://schema.org`,
        "@graph": [
          {
            "@type": "Organization",
            "@id": "https://freejapaneselessons.com/#organization",
            name: "Free Japanese Lessons",
            url: "https://freejapaneselessons.com",
            sameAs: [
              "https://twitter.com/japaneselessons",
              "https://www.linkedin.com/in/freejapaneselessons/"
            ],
            logo: {
              "@type": "ImageObject",
              "@id": "https://freejapaneselessons.com/#logo",
              inLanguage: "en-US",
              url: "https://freejapaneselessons.com/logo.svg",
              caption: "Free Japanese Lessons"
            },
            image: {
              "@id": "https://freejapaneselessons.com/#logo"
            }
          },
          {
            "@type": `WebSite`,
            "@id": "https://freejapaneselessons.com/#website",
            url: `https://freejapaneselessons.com`,
            name: `Free Japanese Lessons`,
            description: `Learn Japanese with Free Japanese Lessons - Learn to speak the Japanese language online for free!`,
            publisher: {
              "@id": "https://freejapaneselessons.com/#organization"
            },
            author: {
              "@type": `Person`,
              name: `Ichika Yamamoto`
            }
          },
          {
            "@type": "ImageObject",
            "@id": `${canonicalUrl + "/"}#primaryimage`,
            inLanguage: "en-US",
            url: "/og-image.jpg",
            caption: metaTitle
          },
          {
            "@type": "WebPage",
            "@id": `${canonicalUrl + "/"}#webpage`,
            inLanguage: "en-US",
            name: metaTitle,
            isPartOf: {
              "@id": "https://freejapaneselessons.com/#website"
            },
            primaryImageOfPage: {
              "@id": `${canonicalUrl + "/"}#primaryimage`
            },
            description: metaDescription
          }
        ]
      }
    ]

    const articleSchema = [
      {
        "@context": "https://schema.org",
        "@type": "Article",
        "@id": `${canonicalUrl + "/"}#article`,
        name: metaTitle,
        headline: metaTitle,
        url: canonicalUrl + "/",
        image: {
          "@id": `${canonicalUrl + "/"}#primaryimage`
        },
        author: {
          "@type": "Person",
          name: "Ichika Yamamoto"
        },
        publisher: {
          "@id": "https://freejapaneselessons.com/#organization"
        },
        potentialAction: [
          {
            "@type": "ReadAction",
            target: [canonicalUrl + "/"]
          }
        ],
        mainEntityOfPage: {
          "@id": `${canonicalUrl + "/"}#webpage`
        }
      }
    ]

    return (
      <Layout {...this.props} lang="en">
        <Helmet
          htmlAttributes={{
            lang: "en"
          }}>
          {metaTitle ? (
            <title>{metaTitle + ` - ` + config.header.title}</title>
          ) : null}
          {metaTitle ? <meta name="title" content={metaTitle} /> : null}
          {metaDescription ? (
            <meta name="description" content={metaDescription} />
          ) : null}
          {metaTitle ? <meta property="og:title" content={metaTitle} /> : null}
          {metaDescription ? (
            <meta property="og:description" content={metaDescription} />
          ) : null}
          {metaTitle ? (
            <meta property="twitter:title" content={metaTitle} />
          ) : null}
          {metaDescription ? (
            <meta property="twitter:description" content={metaDescription} />
          ) : null}
          {keywords ? (
            <meta
              name="keywords"
              http-equiv="keywords"
              content={keywords}></meta>
          ) : null}
          <meta property="og:image" content="/og-image.jpg" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content={isFront ? canonicalUrl : canonicalUrl + "/"}
          />
          <link
            rel="canonical"
            href={isFront ? canonicalUrl : canonicalUrl + "/"}
          />
          <script type="application/ld+json">
            {JSON.stringify(schemaOrg)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(articleSchema)}
          </script>

          <script type="text/javascript">
            {`var _dcq = _dcq || [];
		      var _dcs = _dcs || {};
		        _dcs.account = '5068302';

			  (function() {
			      var dc = document.createElement('script');
				      dc.type = 'text/javascript'; dc.async = true;
				      dc.src = '//tag.getdrip.com/5068302.js';
				      var s = document.getElementsByTagName('script')[0];
				      s.parentNode.insertBefore(dc, s);
				    })();`}
          </script>
        </Helmet>
        <div className={"titleWrapper"}>
          <div className={"title"}>{mdx.frontmatter.subtitle}</div>
          <h1>{mdx.fields.title}</h1>
          <Edit className={"mobileView"}></Edit>
        </div>
        <div className={"mainWrapper"}>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </div>
        {hasRank && (
          <div className={"addPaddTopBottom"}>
            <NextPrevious mdx={mdx} nav={nav} />
          </div>
        )}

          <Announce />
	    {/*
        <div className="anndiv">
          <h2 style={{textAlign: `center`}}>
            EPIC <span style={{color: `#f44336`}}>Christmas</span> Japanese
            Course Sale
          </h2>
          <p style={{textAlign: `center`, padding: `0 20px`}}>
            Our good friends over at Rocket Japanese are currently running a
            big Christmas sale on their very popular Japanese course.
          </p>
          <p style={{textAlign: `center`, padding: `0 20px`}}>
            <strong>The offer:</strong> 60% off.
          </p>
          <p style={{textAlign: `center`, padding: `0 20px`}}>
            <strong>4 days only.</strong>
          </p>
          <div style={{textAlign: `center`}}>
            <RktButton
              href="/goto/rkt-register"
              target="_blank"
              rel="noreferrer noopener nofollow">
              SHOW ME
            </RktButton>
          </div>
        </div> 

*/}

        <div className={"copyright"}>
          © Free Japanese Lessons, {new Date().getFullYear()}. NAGEL LLC.{" "}
          <Link to="/privacy-policy/">Privacy</Link>.
          <Link to="/disclaimer/">Affiliate Disclaimer</Link>.
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
        docsLocation
      }
    }
    mdx(fields: {id: {eq: $id}}) {
      fields {
        id
        title
        slug
      }
      body
      tableOfContents
      parent {
        ... on File {
          relativePath
        }
      }
      frontmatter {
        metaTitle
        subtitle
        metaDescription
        keywords
        rank
      }
    }
    allMdx {
      edges {
        node {
          fields {
            slug
            title
          }
        }
      }
    }
  }
`

const RktButton = styled.a`
  background-color: #f44336;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  font-weight: 700 !important;
  font-size: 1.2em !important;
  padding: 0.1rem 0.5rem 0.1rem 0.5rem !important;
  transition: 0.5s;
  &:hover {
    box-shadow: 0 4px 35px 0 rgba(255, 255, 255, 0.4),
      0 6px 35px 0 rgba(255, 255, 255, 0.4) !important;
    color: #fff !important;
  }
`
