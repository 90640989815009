import React from "react"
import styled from "styled-components"
import { getRocketSale } from "../hooks/rocket-sale"
import Countdown from "./Countdown"

export function Announce(props) {
  let rocketArray = []
  rocketArray = getRocketSale()
  const isSale = rocketArray.status === true
  return (
    <>
    {isSale && (
    <div className="anndiv">
    <h2 style={{textAlign: `center`}}>
      {rocketArray.adjective} <span style={{color: `#f44336`}}>{rocketArray.occasion}</span> Japanese
      Course Sale
    </h2>
    <p style={{textAlign: `center`, padding: `0 20px`}}>
      Our good friends over at Rocket Japanese are currently running a
      big {rocketArray.occasion} sale on their very popular Japanese course.
    </p>
    <p style={{textAlign: `center`, padding: `0 20px`}}>
      <strong>THE OFFER:</strong> 60% off.
    </p>
    <p style={{textAlign: `center`, padding: `0 20px`}}>
      <em>There's a free trial period, gift certificates and a 60 day money-back guarantee too.</em>
    </p>
    <p style={{textAlign: `center`, padding: `0 20px`}}>
      <strong>4 days only.</strong>
    </p>
    <div style={{textAlign: `center`}}>
    <Countdown
            date={rocketArray.date}
            time={rocketArray.time}
            timezone={rocketArray.timezone}
          />
      <RktButton
        href="/goto/rkt-register"
        target="_blank"
        rel="noreferrer noopener nofollow">
        SHOW ME
      </RktButton>
    </div>
  </div>
    )}
    </>
  )
}

const RktButton = styled.a`
  background-color: #f44336;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  font-weight: 700 !important;
  font-size: 1.2em !important;
  padding: 0.1rem 0.5rem 0.1rem 0.5rem !important;
  transition: 0.5s;
  &:hover {
    box-shadow: 0 4px 35px 0 rgba(255, 255, 255, 0.4),
      0 6px 35px 0 rgba(255, 255, 255, 0.4) !important;
    color: #fff !important;
  }
`
